import * as React from "react";
import { Link } from "gatsby";

import Layout from "../../components/Layout";
import Seo from "../../components/Seo";

import Old2005BlogPage from "../../components/Old2005BlogPage";

const OldBlogPage = () => (
  <Layout>
    <Seo title="Old 2005 Blog—March 2007" />
	<Old2005BlogPage>
		<h2>March 2007</h2>



		<h3 className="blogdate">
		Tuesday, March 13, 2007
		</h3>






		<h4 className="blogitemtitle">
		Debauchery and other divinities
		</h4>





			<div className="blogitembody">
			On Thursday, I floated into the twilight, over a large crowd of spectators, guitar for voice and voice for guitar, soaring and beholding the spectacularity of Gravity and Other Myths.<br /><br />On Friday, Shady passed to me the pipe-of-resonant-thoughts, and I witnessed the blues ooze like warm brie off of Alan Norman's celestial accordian, and The Warratahs were wicked.<br /><br />On Saturday, we defied gravity (and other myths) and narrowly dodged Sunday; the turn-tables got dizzy, the burlesque danced, and the keg poured Strong Ale until it passed out, at which point  medium-white wine braced the insides of our skulls against pending implosion.  We slept lustily.<br /><br />I'm loathe to return to a normal day.
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 7:03 PM &nbsp;
			</div>






		<h3 className="blogdate">
		Friday, March 09, 2007
		</h3>






		<h4 className="blogitemtitle">
		Okay, I'm American
		</h4>





			<div className="blogitembody">
			To those Kiwis that ask me... yes, I'm "American" - yes, I'm from "America".  Specifically, I'm from North America - precisely, Canada.  Canada is part of the Americas.  So is Brazil.<br /><br />To those Kiwis that ask me what state I'm from... I'm from the provinces of British Columbia and Alberta.  Canada has provinces, not states.<br /><br />To those Kiwis that have asked me if I'm from Canada, or asked me where I'm from... I'm impressed that you didn't assume I'm "American".<br /><br />To those Kiwis that don't ask, but explicitly assume I'm "American"... you really do intrigue me!
			</div>
			<div className="blogitemfooter">
			posted by Jesse @ 1:17 PM &nbsp;
			</div>





		<p>
			<Link to="/blog2005/">Go back to the blog index page</Link>
		</p>
</Old2005BlogPage>
  </Layout>
)

export default OldBlogPage;
